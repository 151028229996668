body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: white;
  min-height: 100%;
  position: relative;
}

html {
  height: 100%;
}

.App-header {
  color: black;
  overflow: auto;
}

header {
  padding: 1rem;
  font-size: 3rem;
  background-color: black;
}

footer {
  bottom: 0;
  position: absolute;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: black;
  text-align: right;
  color: white;
}

footer div
{
  margin-left: 30px;
  margin-right: 30px;
}

footer a {
  color: white;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

footer .copyright
{
  float: left;
}

.Durian-logo {
  float: left;
  margin-left: 20px;
  margin-top: 10px;
}

.navBar {
  font-size: 20px;
  color: white;
  text-align: center;
  float: right;
  margin-right: 20px;
}

.navBarMenu {
  display: none;
  color: white;
  float: right;
  margin-right: 20px;
  font-size: 40px;
  margin-top: 5px;
  cursor: pointer;
}

.navBar ul li.selected, .navBar ul li:hover {
  border-bottom: 0.18rem solid #b5d759;
  padding-bottom: 6px;
}

.navBar ul li {
  margin-bottom: 10px;
  margin-left: 25px;
  margin-right: 25px;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;

  border-bottom: 0.22rem solid black;
  padding-bottom: 6px;
}

.navBar svg {
  color: white;
}

.navBar a {
  text-decoration: none;
  color:  white;
  padding-left: 0.7rem;
}

.navBar ul {
  list-style-type: none;
}

.App-logo {
  width: 10rem;
}

.App-link {
  color: #61dafb;
}

.main {
  color: black;
  margin: 40px;
  padding: 40px;
  left: auto;
  right: auto;
  margin: auto;
  line-height: 25px;
  overflow: auto;

  padding-bottom: 100px;
}

.main a {
  text-decoration: none;
  font-weight: bold;
  color: black;
}

.AppContainerContainer
{
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  max-width: 600px;
}

.AppContainer
{
  background-color: white;
  padding: 15px;
  margin: 10px;
  text-align: center;
  flex: 1 1;
  font-size: 20px;
  border-radius: 7px;
}

.AppContainer:hover
{
  background-color: whitesmoke;
  cursor: pointer;
}

.AppContainer > img
{
  height: 150px;
  margin-bottom: 10px;
}

.AppContainer div
{
  color: black;
  font-weight: 400;
}

.icons
{
  padding-top: 10px;

}

h2
{
  font-weight: 400;
  color: black;
}

.platformIcon
{
  transform: scale(1.4);
  padding-left: 10px;
  padding-right: 10px;
  color: rgb(199, 199, 199);
}


.AppDetails, .Screenshots
{
  background-color: white;
  border-radius: 7px;
  color: black;
}

.Screenshots {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  min-width: 300px;
}

.Screenshots .imgWrapper {
  /* margin: 10px; */
  flex: 1 1;
  flex-grow: 1;
  margin-bottom: 10px;
  margin-top: 10px;
}

.InfoBox {
  padding: 10px;
  display: flex;
  background-color: lightgoldenrodyellow;
  align-items: center;
  flex-direction: row;
  font-style: italic;
}

.InfoBox img {
  height: 50px;
  width: 50px;
  padding-right: 20px;
}

.Rules .center {
  text-align: center;
}

.Rules .ImageGallery img {
  width: 20%;
  margin: 5px;
}

@media only screen and (min-device-width: 700px) {
  .AppContainerContainer::after {
    content: '';
    flex-grow: 1000000000;
  }
}

@media only screen and (max-device-width: 480px) {
  .navBar {
    display: none;
    float: left;
  }

  .main {
    padding: 20px;
    padding-bottom: 100px;
  }

  .support-footer {
    display: none;
  }

  .AppContainer {
    margin-left: 45px;
    margin-right: 45px;
  }

  .Durian-logo {
    margin-top: 0px;
  }

  .navBarMenu {
    display: block;
  }

  .AppIconContainer.AppIconContainer {
    float: none;
    margin-left: 10px;
  }

  .DownloadIcons {
    width: 100%;
  }
}

.Screenshots img {
  width: 300px;
}

.IconsContainers
{
  overflow: auto;
}

.AppIconContainer img
{
  width: 100px;
}

.AppIconContainer
{
  float: left;
  text-align: center;
  overflow: auto;
  margin-left: 10px;
}

.DownloadIcons {
  float: right;
  text-align: center;
  overflow: auto;
}
.DownloadIcons img
{
  height: 100px;
}

.form {
  color: black;
}

form {
  max-width: 300px;
  margin: 10px auto;
  padding: 10px 20px;
  background: #f4f7f8;
  border-radius: 8px;
}

h1 {
  margin: 0 0 30px 0;
  text-align: center;
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea,
select {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  font-size: 16px;
  height: auto;
  margin: 0;
  outline: 0;
  padding: 15px;
  width: 100%;
  background-color: #e8eeef;
  color: #8a97a0;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  margin-bottom: 30px;
  box-sizing: border-box;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 0 4px 8px 0;
}

select {
  padding: 6px;
  height: 32px;
  border-radius: 2px;
}

button {
  padding: 19px 39px 18px 39px;
  color: #FFF;
  background-color: #4bc970;
  font-size: 18px;
  text-align: center;
  font-style: normal;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #3ac162;
  border-width: 1px 1px 3px;
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
  margin-bottom: 10px;
}

fieldset {
  margin-bottom: 30px;
  border: none;
}

legend {
  font-size: 1.4em;
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 8px;
}

label.light {
  font-weight: 300;
  display: inline;
}

.number {
  background-color: #5fcf80;
  color: #fff;
  height: 30px;
  width: 30px;
  display: inline-block;
  font-size: 0.8em;
  margin-right: 4px;
  line-height: 30px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 100%;
}

form {
  max-width: 100%;
}

form button {
  cursor: pointer;
}

.showmore {
  border-top: 2px solid rgb(172, 172, 172);;
  color: rgb(172, 172, 172);
  font-weight: 700;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 30px;
  width: 200px;
  text-align: center;
  padding: 10px;
}
